import { createRouter, createWebHistory } from 'vue-router'
import webVitals from '@/plugins/web-vitals'

import Home from '@/pages/Home'

const defaultStoreUri = process.env.VUE_APP_STORE_URL

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
	},
	{
		path: '/about',
		name: 'About',
		component: () => import('@/pages/About'),
	},
	{
		path: '/partnership',
		name: 'Partnership',
		component: () => import('@/pages/Partnership'),
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
})

function getPathRecursive(route, parentPath = undefined) {
	let returnArr = []
	let selfPath = parentPath ? `${parentPath}/${route.path}` : route.path
	returnArr.push(selfPath)
	if (route.children)
		route.children.forEach(child => {
			returnArr.push(...getPathRecursive(child, selfPath))
		})
	return returnArr
}

const supportedPath = routes.reduce((fin, r) => {
	const returned = getPathRecursive(r)
	fin.push(...returned)
	return fin
}, [])

router.beforeEach((to, from, next) => {
	if (!supportedPath.includes(to.path)) {
		window.location.href = `${defaultStoreUri}${to.fullPath}`
	}

	if (Object.keys(from.query).length > 0) {
		const mergedQuery = { ...to.query, ...from.query }
		if (JSON.stringify(to.query) !== JSON.stringify(mergedQuery)) {
			next({ ...to, query: mergedQuery, replace: true })
		}
	}

	next()
})

webVitals(router)

export default router
